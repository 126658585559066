import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import Layout from 'components/Layout'
import SEO from 'components/Seo'
import Social from '../components/Social'
import { IntlProvider } from 'contexts/intl/IntlContext'
import { StaticImage } from 'gatsby-plugin-image'

const Home = ({ location }) => (
  <IntlProvider pathname={location.pathname}>
    {({ locale, formatMessage, getPath }) => {
      return (
        <Layout>
          <SEO
            isBlogPost={false}
            lang={locale}
            title={formatMessage({ id: 'home.title' })}
            description={formatMessage({ id: 'meta.description' })}
            keywords={formatMessage({ id: 'meta.keywords' })}
            url={formatMessage({ id: 'meta.url' })}
          />
          <div className='container'>
            <div className='columns is-mobile'>
              <div className='column'>
                <figure className='photo image is-128x128'>
                  <StaticImage
                    src='../../static/img/mihirpipermitwala.png'
                    alt={'Mihir Pipermitwala'}
                    width={128}
                    height={128}
                    layout='fixed'
                    quality={100}
                    placeholder='blurred'
                    style={{ borderRadius: '100%' }}
                  />
                </figure>
                <h1 className='title has-text-centered has-text-light'>
                  <FormattedMessage id='profileName' />
                </h1>
                <h2 className='subtitle has-text-centered has-text-warning'>
                  <FormattedMessage id='home.headline' />
                </h2>
              </div>
            </div>
            <Social />
          </div>
          <section className='section is-size-4-desktop is-size-5-touch'>
            <div className='container content'>
              <h4 className='title has-text-light emoji-waving'>
                <FormattedMessage id='home.greet' />
              </h4>
              <p>
                <FormattedMessage id='home.about' />
              </p>
              <p>
                <FormattedMessage id='checkOut' />{' '}
                <Link to={getPath('blogs')}>
                  <FormattedMessage id='blogs.title' />
                </Link>
                {'.'}
              </p>
            </div>
          </section>
        </Layout>
      )
    }}
  </IntlProvider>
)

Home.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default Home
