import React from 'react'
import siteMetadata from '../../../gatsby-meta-config'
import { FaGithub, FaLinkedinIn, FaMedium, FaTwitter, FaEnvelope, FaInstagram } from 'react-icons/fa6'

const Social = () => {
  const customSocial = siteMetadata.social

  const icons = {
    github: FaGithub,
    twitter: FaTwitter,
    linkedin: FaLinkedinIn,
    instagram: FaInstagram,
    medium: FaMedium,
    email: FaEnvelope,
  }

  return (
    <div className='has-text-centered is-mobile'>
      {customSocial.map(item => {
        const Icon = icons[item.name]
        return (
          <a
            href={item.url}
            key={item.name}
            aria-label={item.title}
            title={item.title}
            target='_blank'
            rel='noopener noreferrer'
          >
            <span className='icon is-large has-text-light'>
              <Icon className='fab fa-2x' />
            </span>
          </a>
        )
      })}
    </div>
  )
}

export default Social
