module.exports = {
  title: 'Mihir Pipermitwala',
  ogTitle: 'Mihir Pipermitwala',
  twitterTitle: 'Mihir Pipermitwala',
  author: 'mihirpipermitwala',
  copyright: ``,
  description: 'Here you can find my thoughts, ideas and Mobile Development Journal.',
  ogDescription: 'Here you can find my thoughts, ideas and Mobile Development Journal.',
  twitterDescription: 'Here you can find my thoughts, ideas and Mobile Development Journal.',
  image: 'https://mihirpipermitwala.com/img/mihirpipermitwala.png',
  siteUrl: 'https://mihirpipermitwala.com',
  siteName: 'mihirpipermitwala.com',
  keywords: [
    'ios',
    'swift',
    'objective-c',
    'flutter',
    'dart',
    'android',
    'kotlin',
    'news',
    'tutorials',
    'macos',
    'watchos',
    'tvos',
    'visionos',
    'swift 5',
    'swiftui',
    'ios 16',
    'ipad',
    'iphone',
    'guide',
    'how-to',
    'xcode',
    'xcode-14',
    'uikit',
    'watchkit',
    'mihir pipermitwala',
    'mobile app developer',
    'freelancer',
    'contract',
    'part-time',
    'full-time',
    'mobile app coding strategies',
    'code mastery',
    'flutter app development tips',
    'platform-specific customization',
    'iOS and Android apps Developement',
    'programming beginners',
    'australia',
    'flutter training',
    'flutter tutorials',
    'india',
    'flutter developer',
    'ios developer',
    'surat',
  ],
  user: {
    name: 'Mihir Pipermitwala',
    github: 'mihirpipermitwala',
  },
  contactPostAddress: '',
  social: [
    { name: 'github', url: `https://github.com/mihirpipermitwala`, title: 'GitHub' },
    { name: 'instagram', url: `https://instagram.com/mihirpipermitwala`, title: 'Instagram' },
    { name: 'twitter', url: `https://twitter.com/MPipermitwala`, title: 'Twitter' },
    { name: 'medium', url: `https://medium.com/@mihirpipermitwala`, title: 'Medium' },
    { name: 'linkedin', url: `https://www.linkedin.com/in/mihir-pipermitwala-5b531b9a/`, title: 'LinkedIn' },

    // { name: 'email', url: `mihirpipermitwala@gmail.com`, title: 'E-Mail' },
    // { name: 'facebook', url: `https://facebook.com` },
  ],
  foundingDate: '2021',
}
